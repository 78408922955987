<script lang="ts" setup>
import { computed, toRefs } from 'vue';
import { useProductPrices } from '~/composables/useProductPrices';

const props = defineProps<{
    productNumber: string;
    isArchiveArticle?: boolean;
    isSaleProduct?: boolean;
    context?: string;
}>();

const { productNumber } = toRefs(props);
const { isLoggedIn } = useUser();
const { getPrice, isLoading, formatPrice, formatDisplayPrice } = useProductPrices();
const priceInfo = computed(() => getPrice(productNumber.value));
</script>

<template>
    <ClientOnly>
        <div
            v-if="isLoading"
            class="flex justify-end"
        >
            <SharedLoader class="!fill-gray-300" />
        </div>
        <div
            v-else-if="isLoggedIn"
            :data-testid="`price-wrapper-${productNumber}`"
            v-bind="$attrs"
        >
            <template v-if="priceInfo">
                <div
                    v-if="!priceInfo.unbuyable && !isArchiveArticle"
                    class="text-right whitespace-nowrap"
                >
                    <!-- price-->
                    <div
                        v-if="priceInfo.strokePrice && (context !== 'wishlist' || isSaleProduct)"
                        class="flex items-baseline justify-end flex-wrap"
                    >
                        <div
                            class="line-through text-sm"
                            v-html="$t('prices.onlyPrice', { price: formatPrice(priceInfo.strokePrice) })"
                        />

                        <div class="font-bold">
                            <span>&nbsp;</span>
                            <span
                                v-if="priceInfo.price"
                                class="text-brand-danger"
                                v-html="$t('prices.onlyPrice', { price: formatPrice(priceInfo.price, 3) })"
                            />
                        </div>
                    </div>
                    <div
                        v-else-if="priceInfo.fromPrice"
                        class="font-bold"
                    >
                        {{ $t('fromPriceText') }}
                        <span v-html="$t('prices.onlyPrice', { price: formatPrice(priceInfo.fromPrice, 3) })" />
                    </div>
                    <div
                        v-else-if="priceInfo.price"
                        class="font-bold"
                        v-html="$t('prices.onlyPrice', { price: formatPrice(priceInfo.price, 3) })"
                    />

                    <div
                        v-if="priceInfo?.discount && context !== 'search'"
                        class="whitespace-normal hyphens-auto text-xs mb-1 text-brand-danger"
                    >
                        {{ $t('priceDiscountPercentBefore') }} {{ priceInfo.discount }} {{ $t('priceDiscountPercent')
                        }}<br />
                        <span v-if="context !== 'listing' && !isSaleProduct">({{ $t('customerDiscountLabel') }})</span>
                    </div>

                    <!-- displayPrice-->
                    <div
                        v-if="priceInfo.displayFromPrice"
                        v-html="$t('prices.onlyPrice', { price: formatDisplayPrice(priceInfo.displayFromPrice, 3) })"
                    />
                    <div
                        v-else-if="priceInfo.displayPrice"
                        v-html="$t('prices.onlyPrice', { price: formatDisplayPrice(priceInfo.displayPrice, 3) })"
                    />
                </div>
                <div
                    v-else-if="priceInfo.unbuyableTitle"
                    class="text-right font-bold"
                    v-html="priceInfo.unbuyableTitle"
                ></div>
            </template>
        </div>

        <template #fallback>
            <div class="flex justify-end">
                <SharedLoader class="!fill-gray-300" />
            </div>
        </template>
    </ClientOnly>
</template>
